import {
    atom
} from "recoil";

export const menu = atom({
    key: 'menu',
    default: []
});

export const categories = atom({
    key: 'categories',
    default: []
});

export const franchises = atom({
    key: 'franchises',
    default: []
});

export const customer = atom({
    key: 'customer',
    default: {}
});

export const address = atom({
    key: 'address',
    default: ''
});

export const sidebaropen = atom({
    key: 'sidebaropen',
    default: false
});

export const itemToRemove = atom({
    key: 'itemtoremove',
    default: ''
});

export const toggleModalRemoveItem = atom({
    key: 'modalremoveitemstate',
    default: false
});

export const isReturnToCart = atom({
    key: 'isreturntocart',
    default: false
});

export const isHalaal = atom({
    key: 'ishalaal',
    default: false
});

export const size = atom({
    key: 'size',
    default: 1
});

export const isValidCustomer = atom({
    key: 'isvalidacustomer',
    default: false
});

export const cart = atom({
    key: 'cart',
    default: {
        "qty": 0,
        "total": 0
    }
});