import { useState } from "react";
import Link from "next/link";
import Image from "next/legacy/image";
import {
    useSetRecoilState,
    useRecoilValue
} from "recoil";
import { useDoc } from "use-pouchdb";
import iconSet from "../../utilities/selection.json";
import IcomoonReact, { iconList } from "icomoon-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileInvoice, faRecycle } from "@fortawesome/free-solid-svg-icons";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import tw, { styled } from "twin.macro";
import { sidebaropen } from "../../state/custom";
import each from "lodash/each";
import filter from "lodash/filter";

export default function Sidebar() {
    const [hasError, setErrors] = useState(false);
    const _sidebaropen = useRecoilValue(sidebaropen);
    const setSidebarOpen = useSetRecoilState(sidebaropen);
    const { doc: categories, loading: categoriesLoading } = useDoc('categories');
    const { doc: subcategories, loading: subcategoriesLoading } = useDoc('subcategories');

    if (!categoriesLoading && !subcategoriesLoading && categories && subcategories) {

        each(categories.menucategories, (level1) => {
            let found = filter(subcategories.menusubcategories, (level2) => {
                return level2.categoryId === level1.categoryId;
            });

            if (found) {
                level1.subcategories = found
            }
        })
    }

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        iconList(iconSet);
        setSidebarOpen(open);
    };

    const resetData = () => {
        setErrors(true);
    };

    const list = () => (
        <DrawerContainer role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <Link href="/" legacyBehavior>
                <SidebarLogo style={{ justifyContent: 'center' }}>
                    <Image src={'/logo-black.webp'} alt="Del Forno - The Home of Good Food" width="121" height="80" />
                </SidebarLogo>
            </Link>
            <SplitterContainer>
                <Splitter />
            </SplitterContainer>
            <MenuItemsContainer>
                <Link href={"/loyalty-points"} style={{ textDecoration: 'none' }} legacyBehavior>
                    <SidebarItem style={{ paddingBottom: 4 }}>
                        <Image src={'/imgs/dough.png'} alt="Del Forno - Dough Loyalty" width="30" height="30" />
                        <DoughText>Del Forno Dough</DoughText>
                    </SidebarItem>
                </Link>
            </MenuItemsContainer>
            <MenuItemsContainer>
                <Link href={"/order-history"} style={{ textDecoration: 'none' }} legacyBehavior>
                    <SidebarItem style={{ paddingBottom: 0 }}>
                        <IconCircleBlack>
                            <FontAwesomeIcon icon={faFileInvoice} style={{fontSize: 18}} color={'#fff'} />
                        </IconCircleBlack>
                        <OrderHistoryText>Order History</OrderHistoryText>
                    </SidebarItem>
                </Link>
            </MenuItemsContainer>
            <MenuItemsContainer>               
                <SidebarItem style={{ paddingBottom: 0, marginLeft: -2 }} onClick={() => resetData()}>
                    <IconCircleBlack>
                        <FontAwesomeIcon icon={faRecycle} style={{fontSize: 18}} color={'#fff'} />
                    </IconCircleBlack>
                    <ResetText>Reset</ResetText>
                </SidebarItem>            
            </MenuItemsContainer>
            <SplitterContainer>
                <Splitter />
            </SplitterContainer>
            <MenuItemsContainer>
                {
                    (!categoriesLoading && !subcategoriesLoading && categories && subcategories) ?
                        categories.menucategories.map((item) => {
                            let icon = item.slug.toLowerCase().replace("-", "");
                            let link = '/' + item.slug;
                            return (
                                <MenuItemContainer key={'a' + item.categoryId}>
                                    <Link href={link} style={{ textDecoration: 'none' }} legacyBehavior>
                                        <SidebarItem>
                                            <IconCircle>
                                                <IcomoonReact iconSet={iconSet} color={'#ffffff'} size={16} icon={icon} />
                                            </IconCircle>
                                            <CategoryText>{item.category}</CategoryText>
                                        </SidebarItem>
                                    </Link>
                                    {
                                        item.subcategories.map((subcategory, index) => {
                                            let sublink = link + '/' + subcategory.slug;
                                            let key = subcategory.subCategoryId + 'a' + index;
                                            return <Link href={sublink} key={key} style={{ textDecoration: 'none' }}>
                                                <SidebarSubItem>
                                                    <SubCategoryText> - {subcategory.subCategory}</SubCategoryText>
                                                </SidebarSubItem>
                                            </Link>
                                        })
                                    }
                                </MenuItemContainer>
                            );
                        })
                        : <></>
                }
            </MenuItemsContainer>
        </DrawerContainer>
    );

    if (hasError) {
        throw new Error("Error");
    }

    return (
        <MenuContainer>
            <SwipeableDrawer anchor={'left'} open={_sidebaropen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
                {list()}
            </SwipeableDrawer>
        </MenuContainer>
    );
}

const DoughText = styled.div`
    ${tw`text-white text-sm pl-[12px] pt-[6px]`}
`;

const ResetText = styled.div`
    ${tw`text-white text-sm pl-[10px] pt-[8px]`}
`;

const OrderHistoryText = styled.div`
    ${tw`text-white text-sm pl-[12px] pt-[8px]`}
`;

const CategoryText = styled.div`
    ${tw`text-white text-sm pl-[12px] pt-[4px]`}
`;

const SubCategoryText = styled.div`
    ${tw`text-white text-sm pl-[12px]`}
`;

const Splitter = styled.div`
    ${tw`w-28 h-[1px] bg-grey`}
`;

const SplitterContainer = styled.div`
    ${tw`w-full flex justify-center mt-4 mb-4`}
`;

const IconCircle = styled.div`
    ${tw`flex flex-row items-center bg-red p-[6px] rounded-full`}
`;

const IconCircleBlack = styled.div`
    ${tw`flex flex-row items-center bg-black p-[8px] rounded-full`}
`;

const SidebarLogo = styled.div`
    ${tw`flex flex-row pt-4 justify-center cursor-pointer text-white`}
`;

const SidebarItem = styled.div`
    ${tw`flex flex-row cursor-pointer text-white`}
`;

const SidebarSubItem = styled.div`
    ${tw`flex flex-row cursor-pointer text-white pl-10`}
`;

const DrawerContainer = styled.div`
    ${tw`w-[250px] h-full bg-black`}
`;

const MenuItemContainer = styled.div`
    ${tw`pb-2 bg-black`}
`;

const MenuItemsContainer = styled.div`
    ${tw`pl-4 bg-black`}
`;

const MenuContainer = styled.div`
    ${tw`overflow-hidden fixed`}
`;

